<template>
  <!-- 具体问题 -->
  <div class="content_c">
    <!-- 标题 -->
    <div v-for="item in problems" :key="item.id">
      <div class="title">
        <span class="titile_long">
          <!-- ///悬赏金额 -->
          <span v-if="item.competePriceSetting > 0">
            <span class="Gold_coin"></span>
            <span class="plus">+</span>
            <span class="plus">{{ item.competePriceSetting }}</span>
          </span>

          <!-- 悬赏金额 -->
          <!-- <span v-if="item.viewPrice>0">
            <img class="Gold_coin" />
            <span class="plus">+</span>          
            <span class="plus">{{ item.viewPrice }}</span>
          </span> -->
          <!-- 标题 -->
          <span class="words" @click="details(item.id)">{{
            item.questionTitle
          }}</span>
        </span>
        <!-- <span class="Fork"></span> -->
      </div>
      <!-- 内容 -->
      <div
        class="Information"
        v-if="
          item.questionContent == undefined ||
            item.questionContent.length <= 100
        "
      >
        {{ item.questionContent == undefined ? "" : item.questionContent }}
      </div>
      <!-- 内容 -->
      <div
        class="Information"
        v-if="
          item.questionContent == undefined || item.questionContent.length > 100
        "
      >
        {{
          item.questionContent == undefined
            ? ""
            : item.questionContent.substring(0, 100) + "..."
        }}
      </div>
      <!-- 分类 -->
      <div class="Classification">
        <span class="left">
          <span class="Pen"></span>
          <span v-show="item.keyword != '' && item.keyword != null">
            <span
              class="edge title12"
              @click="keyWordSearch(word)"
              v-for="(word, index) in JSON.parse(item.keyword)"
              :key="index"
            >
              <span v-if="word.length < 10">{{ word }}</span>
              <span v-if="word.length >= 10">{{
                word.substring(0, 10) + "..."
              }}</span>
            </span>
          </span>
        </span>
        <span class="right">
          <span class="answer">{{ item.answerNum }}</span
          >回答 ·
          <span class="Front">{{ item.createTime }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  QUERY,
  UPDATE,
  DELETE,
  INSERT,
  UPDATES,
  QUERYED,
} from "@/services/dao.js";
export default {
  props: {
    problems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    keyWordSearch(word) {
      console.log(111111111111);
      this.$router.push({
        path: "/automatic",
        query: {
          word: word,
        },
      });
    },
    async details(id) {
      // this.$emit('browse');
      console.log("id:" + id);
      let info = await QUERYED(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " + id + "}}) { scanNum  }"
      );
      console.log("热度值" + info.data.WDNewQuestion[0].scanNum);
      let anser = Number(info.data.WDNewQuestion[0].scanNum);
      anser += 1;
      console.log("热度值2:" + anser);
      let dataed = await UPDATES(
        "post",
        "",
        "   update_WdQuestion(where: {id: {_eq: " +
          id +
          "}}, _set: {scanNum: " +
          anser +
          "}) { affected_rows  }"
      );
      if (dataed.data.update_WdQuestion.affected_rows > 0) {
        this.$router.push({
          path: "/writeanswer",
          query: {
            id: id,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.title12 {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title12:hover {
  cursor: pointer;
  background-color: #fd6e05;
  color: white;
  border:0;
  border-color: white;
}
.content_c {
  /* border: 1px solid red; */
  width: 100%;
  margin: auto;
  display: inline-block;
}
.title,
.Information,
.Classification {
  width: 100%;
  /* height: 35px; */
  line-height: 35px;
  /* border: 1px solid yellow; */
}
.Information {
  font-size: 14px;
  word-wrap: break-word; /* 自动给英文断行 */
}
/* 金币 */
.Gold_coin {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../assets/icon/Gold.png");
  background-repeat: no-repeat;
  position: relative;
  /* border: 1px solid red; */
  top: 5px;
  display: inline-block;
}
/* 叉 */
.Fork {
  width: 20px;
  height: 20px;
  background-image: url("../../../assets/icon/fork.png");
  background-repeat: no-repeat;
  display: inline-block;
  text-align: right;
  position: relative;
  top: 10px;
  text-align: right;
}
.Pen {
  height: 18px;
  width: 15px;
  background-image: url("../../../assets/pen4.png");
  background-color: 18px 18px;
  /* border: 1px solid red; */
  /* background-position-x: 518px;
  background-position-y: -38px; */
  background-size: 15px 15px;
  display: inline-block;
  background-repeat: no-repeat;
  position: relative;
  top: 5px;
  margin-right: 5px;
}
.edge {
  border: 1px solid #8590a6;
  border-radius: 13px;
  padding: 3px;
  margin-right: 5px;
  color: #8590a6;
}
.Classification {
  display: inline-block;
  padding-bottom: 8px;
  /* border: 1px solid green; */
  border-bottom: 1px dashed #686868;
}
.left {
  width: 60%;
  /* border: 1px solid orangered; */
  display: inline-block;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}
.titile_long {
  width: 95%;
  display: inline-block;
  /* border: 1px solid darkgreen; */
}
.right {
  width: 40%;
  float: right;
  text-align: right;
  height: 35px;
  font-size: 14px;
  /* border: 1px solid red; */
  /* display: inline-block; */
}
.right span {
  margin-right: 3px;
}
.Front {
  margin-left: 5px;
  /* border: 1px solid red; */
  display: inline-block;
}
.plus {
  width: 25px;
  height: 12px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(253, 110, 5, 1);
  line-height: 24px;
}
.words {
  font-weight: bold;
  font-size: 16px;
  margin-left: 3px;
  cursor: pointer;
}
.words:hover {
  color: lightcoral;
}
</style>

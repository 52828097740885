<template>
  <!-- 智能问答-全部问题 -->
  <div class="whole">
    <!-- 上方 -->
    <div class="head">
      <!-- 问题条数
          <div class="head_problem" >共{{problemId}}个问题</div> -->
      <!-- 分类内容 -->
      <div class="classification">
        <div class="center">
          <!-- <V-Classification 
                 :frame="frame"
                 :index="index"
                 :problems="problems"
                  ></V-Classification> -->
          <div class="content2">
            <div
              class="frame"
              v-for="(teamType, index) in frame"
              :key="index"
              :class="'frame' + (index + 2)"
            >
              <span
                :class="{ classification: classification == index }"
                @click="selectItems(index, teamType)"
                >{{ teamType }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 中间 -->
    <!-- 中间导航 -->
    <div class="center_Navigation">
      {{ abqute }}
      <span class="Navigationdddd">
        <span>></span>
        <span>
          <!-- 计算机科学 -->
          {{ teamType1 }}
          <!-- <span class="down"></span> -->
        </span></span
      >
      <!-- 问题条数 -->
      <span class="head_problem">共{{ problemId }}个问题</span>
    </div>

    <!-- 问题条件 -->
    <div class="right">
      <div class="condition">
        <span :class="{ problem: problem == 1 }" @click="selectItem(1)"
          ><span class="tu1"><span class="new"></span></span>最新问题</span
        >
        <span :class="{ problem: problem == 2 }" @click="selectItem(2)"
          ><span class="tu2"><span class="Appreciation"></span></span
          >悬赏问题</span
        >
        <span :class="{ problem: problem == 3 }" @click="selectItem(3)"
          ><span class="tu3"><span class="heat"></span></span>热点问题</span
        >
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="search">
        <!-- 问题 -->
        <div class="Specific_issues">
          <!-- 具体问题 内容-->
          <!-- <router-view style="width:768px;" /> -->
          <V-Redword :problems="problems"></V-Redword>
        </div>
        <!-- 分页 -->
        <div class="page-nav">
          <el-pagination
            background
            layout="prev, pager, next"
            :hide-on-single-page="hidePageNav"
            :total="totlePage"
            :current-page.sync="currentPage1"
            :page-size="pageSize"
            @prev-click="prevPage"
            @next-click="nextPage"
            @current-change="currentPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DELETE, QUERYED } from "@/services/dao.js";
import { formatDate } from "@/js/common";
import Classification from "@/views/wisdom-answer/index/all-questions/problem_classification/index.vue";
// import Specific from "@/components/all_questions/specific-issues/index.vue"
import Redword from "@/components/all_questions/reward/index.vue";
export default {
  data() {
    return {
      problem: 1,
      problemId: 0,
      problems: [],
      frame: [],
      index: 0,
      classification: -1,
      class: "",
      teamType1: "",
      teamType2: "",
      abqute: "最新问题",

      pageNum: 50,
      pageSize: 10,
      totlePage: 5000,
      hidePageNav: true,
      currentPage1: 1,
      //   pageNum:0,
      //   pageSize:12,
      //   totlePage:0,
    };
  },
  components: {
    // 'V-Classification':Classification,
    // 'V-specific':Specific,
    "V-Redword": Redword,
  },
  //   初始化
  created: function() {
    this.init(this.pageSize, 0);
    // 初始加载共计多少问题
    //   this.total();
    ///加载分类
    this.head();
  },
  methods: {
    //前一页
    prevPage(val) {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.pageNum = val - 1;
      if (this.problem == 1 && this.teamType1 == "") {
        //最新
        this.init(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 2 && this.teamType1 == "") {
        //悬赏
        this.reward(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 3 && this.teamType1 == "") {
        //热点
        this.hotspot(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 1 && this.teamType1 != "") {
        //分类+最新
        this.TYnewest(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 2 && this.teamType1 != "") {
        //分类+悬赏
        this.TYreward(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 3 && this.teamType1 != "") {
        //分类+热点
        this.TYhotspot(this.pageSize, this.pageSize * this.pageNum);
      }
    },
    //下一页
    nextPage(val) {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.pageNum = val - 1;
      if (this.problem == 1 && this.teamType1 == "") {
        //最新
        this.init(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 2 && this.teamType1 == "") {
        //悬赏
        this.reward(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 3 && this.teamType1 == "") {
        //热点
        this.hotspot(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 1 && this.teamType1 != "") {
        //分类+最新
        this.TYnewest(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 2 && this.teamType1 != "") {
        //分类+悬赏
        this.TYreward(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 3 && this.teamType1 != "") {
        //分类+热点
        this.TYhotspot(this.pageSize, this.pageSize * this.pageNum);
      }
    },
    //当前页
    currentPage(val) {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.pageNum = val - 1;
      if (this.problem == 1 && this.teamType1 == "") {
        //最新
        this.init(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 2 && this.teamType1 == "") {
        //悬赏
        this.reward(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 3 && this.teamType1 == "") {
        //热点
        this.hotspot(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 1 && this.teamType1 != "") {
        //分类+最新
        this.TYnewest(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 2 && this.teamType1 != "") {
        //分类+悬赏
        this.TYreward(this.pageSize, this.pageSize * this.pageNum);
      } else if (this.problem == 3 && this.teamType1 != "") {
        //分类+热点
        this.TYhotspot(this.pageSize, this.pageSize * this.pageNum);
      }
    },
    //最新全部问题内容显示
    async init(limit, offset) {
      // let info=  await  QUERYED("post","",'  WdQuestion(limit: '+limit+', offset: '+offset+'){ id competePriceSetting questionTitle keyword  questionContent  answerNum   createTime } WdQuestion_aggregate {     aggregate {       count     }   }');
      let info = await QUERYED(
        "post",
        "",
        "WDNewQuestion(limit: " +
          limit +
          ", offset: " +
          offset +
          ") {  id competePriceSetting  questionTitle keyword  questionContent  answerNum   createTime   } WDNewQuestion_aggregate {     aggregate {       count     }   }"
      );
      console.log(info);
      this.problems.splice(0, this.problems.length);
      for (let i = 0; i < info.data.WDNewQuestion.length; i++) {
        this.problems.push({
          id: info.data.WDNewQuestion[i].id,
          competePriceSetting: info.data.WDNewQuestion[i].competePriceSetting,
          questionTitle: info.data.WDNewQuestion[i].questionTitle,
          questionContent: info.data.WDNewQuestion[i].questionContent,
          keyword: info.data.WDNewQuestion[i].keyword,
          answerNum: info.data.WDNewQuestion[i].answerNum,
          createTime: formatDate(
            Date.parse(info.data.WDNewQuestion[i].createTime)
          ),
        });
      }
      this.problemId = info.data.WDNewQuestion_aggregate.aggregate.count;
      this.totlePage = info.data.WDNewQuestion_aggregate.aggregate.count;
      console.log(this.problems);
      //alert(info.data.WdQuestion_aggregate.aggregate.count)
    },
    ///加载显示一共有多少问题
    //   async total(){
    //     let info =await QUERYED("post","",'   WDUserQuestion_aggregate {  aggregate {   count} }');
    //     //alert(info)
    //       this.problemId=info.data.WDUserQuestion_aggregate.aggregate.count;
    //   },
    ///点击事件（最新/热点/悬赏）
    async selectItem(index) {
      this.problem = index;
      // this.classification=0;
      // let info=null;
      // let totals=null;
      //alert(this.teamType1)
      // this.teamType2=this.teamType1;
      if (this.problem == 1) {
        this.currentPage1 = 1;
        this.init(this.pageSize, 0);
        this.teamType1 = "";
        this.classification = -1;
        this.abqute = "最新问题";
        return;
      } else if (this.problem == 2) {
        this.currentPage1 = 1;
        this.reward(this.pageSize, 0);
        this.teamType1 = "";
        this.classification = -1;
        this.abqute = "悬赏问题";
        return;
      } else if (this.problem == 3) {
        this.currentPage1 = 1;
        this.hotspot(this.pageSize, 0);
        this.teamType1 = "";
        this.classification = -1;
        this.abqute = "热点问题";
        return;
      }
    },
    ///查询悬赏问题
    async reward(limit, offset) {
      let info = null;
      let totals = null;
      // this.class=teamType;
      info = await QUERYED(
        "post",
        "",
        "   WDNewQuestion(limit: " +
          limit +
          ", offset: " +
          offset +
          ", where: {_not: {competePriceSetting: {_eq: 0}}}) { id  competePriceSetting    questionTitle  questionContent  keyword  answerNum    createTime }"
      );
      totals = await QUERYED(
        "post",
        "",
        "   WDNewQuestion_aggregate(where: {_not: {competePriceSetting: {_eq: 0}}}) {  aggregate {  count }  }"
      );
      console.log(info);
      this.problems.splice(0, this.problems.length);
      for (let i = 0; i < info.data.WDNewQuestion.length; i++) {
        this.problems.push({
          id: info.data.WDNewQuestion[i].id,
          competePriceSetting: info.data.WDNewQuestion[i].competePriceSetting,
          questionTitle: info.data.WDNewQuestion[i].questionTitle,
          questionContent: info.data.WDNewQuestion[i].questionContent,
          keyword: info.data.WDNewQuestion[i].keyword,
          answerNum: info.data.WDNewQuestion[i].answerNum,
          createTime: formatDate(
            Date.parse(info.data.WDNewQuestion[i].createTime)
          ),
        });
      }
      this.problemId = totals.data.WDNewQuestion_aggregate.aggregate.count;
      this.totlePage = totals.data.WDNewQuestion_aggregate.aggregate.count;
    },
    /////热点查询
    async hotspot(limit, offset) {
      //alert(1)
      let info = null;
      let totals = null;
      // this.class=teamType;
      //alert(this.class)
      info = await QUERYED(
        "post",
        "",
        " WDHotQuestion(limit: " +
          limit +
          ", offset: " +
          offset +
          ") { id  competePriceSetting    questionTitle  questionContent  keyword  answerNum    createTime }"
      );
      totals = await QUERYED(
        "post",
        "",
        "   WDHotQuestion_aggregate {  aggregate {   count} }"
      );
      console.log(info);
      //alert(this.info)
      this.problems.splice(0, this.problems.length);
      for (let i = 0; i < info.data.WDHotQuestion.length; i++) {
        this.problems.push({
          id: info.data.WDHotQuestion[i].id,
          competePriceSetting: info.data.WDHotQuestion[i].competePriceSetting,
          questionTitle: info.data.WDHotQuestion[i].questionTitle,
          questionContent: info.data.WDHotQuestion[i].questionContent,
          keyword: info.data.WDHotQuestion[i].keyword,
          answerNum: info.data.WDHotQuestion[i].answerNum,
          createTime: formatDate(
            Date.parse(info.data.WDHotQuestion[i].createTime)
          ),
        });
      }
      this.problemId = totals.data.WDHotQuestion_aggregate.aggregate.count;
      this.totlePage = totals.data.WDHotQuestion_aggregate.aggregate.count;
    },
    ///加载分类
    async head() {
      //alert(1)
      this.frame.splice(0, this.frame.length);
      let info = await QUERYED("post", "", "TYPE {id  type}");
      console.log(info);
      for (let i = 0; i < info.data.TYPE.length; i++) {
        this.frame.push(info.data.TYPE[i].type);
      }
    },
    async selectItems(index, teamType) {
      let info = null;
      let data = null;
      this.classification = index;
      this.class = teamType;
      this.teamType1 = teamType;
      //alert(this.teamType)
      if (this.problem == 1) {
        this.TYnewest(this.pageSize, 0);
        return;
      } else if (this.problem == 2) {
        this.TYreward(this.pageSize, 0);
        return;
      } else if (this.problem == 3) {
        this.TYhotspot(this.pageSize, 0);
        return;
      }
    },
    /////分类+热点查询
    async TYhotspot(limit, offset) {
      //alert(1)
      let info = null;
      let data = null;
      // this.class=teamType;
      //alert(this.class)
      info = await QUERYED(
        "post",
        "",
        " WDHotQuestion(limit: " +
          limit +
          ", offset: " +
          offset +
          ',where: {problemClassification: {_eq: "' +
          this.class +
          '"}}) {id competePriceSetting questionTitle keyword  questionContent  answerNum   createTime }'
      );
      data = await QUERYED(
        "post",
        "",
        '   WDHotQuestion_aggregate(where: { problemClassification: {_eq: "' +
          this.class +
          '"}}){  aggregate {   count} }'
      );
      console.log(info);
      //alert(this.info)
      this.problems.splice(0, this.problems.length);
      for (let i = 0; i < info.data.WDHotQuestion.length; i++) {
        this.problems.push({
          id: info.data.WDHotQuestion[i].id,
          competePriceSetting: info.data.WDHotQuestion[i].competePriceSetting,
          questionTitle: info.data.WDHotQuestion[i].questionTitle,
          questionContent: info.data.WDHotQuestion[i].questionContent,
          keyword: info.data.WDHotQuestion[i].keyword,
          answerNum: info.data.WDHotQuestion[i].answerNum,
          createTime: formatDate(
            Date.parse(info.data.WDHotQuestion[i].createTime)
          ),
        });
      }
      this.problemId = data.data.WDHotQuestion_aggregate.aggregate.count;
      this.totlePage = data.data.WDHotQuestion_aggregate.aggregate.count;
    },
    ///查询分类+最新问题
    async TYnewest(limit, offset) {
      let info = null;
      let data = null;
      // this.class=teamType;
      //alert(this.class)
      info = await QUERYED(
        "post",
        "",
        " WDNewQuestion(limit: " +
          limit +
          ", offset: " +
          offset +
          ', where: {problemClassification: {_eq: "' +
          this.class +
          '"}}) {id competePriceSetting questionTitle keyword  questionContent  answerNum   createTime }'
      );
      data = await QUERYED(
        "post",
        "",
        '   WDNewQuestion_aggregate(where: {problemClassification: {_eq: "' +
          this.class +
          '"}}) {  aggregate {   count} }'
      );
      console.log(info);
      //alert(info)
      this.problems.splice(0, this.problems.length);
      for (let i = 0; i < info.data.WDNewQuestion.length; i++) {
        this.problems.push({
          id: info.data.WDNewQuestion[i].id,
          competePriceSetting: info.data.WDNewQuestion[i].competePriceSetting,
          questionTitle: info.data.WDNewQuestion[i].questionTitle,
          questionContent: info.data.WDNewQuestion[i].questionContent,
          keyword: info.data.WDNewQuestion[i].keyword,
          answerNum: info.data.WDNewQuestion[i].answerNum,
          createTime: formatDate(
            Date.parse(info.data.WDNewQuestion[i].createTime)
          ),
        });
      }
      this.problemId = data.data.WDNewQuestion_aggregate.aggregate.count;
      this.totlePage = data.data.WDNewQuestion_aggregate.aggregate.count;
    },
    ///查询分类+悬赏问题
    async TYreward(limit, offset) {
      let info = null;
      let data = null;
      // this.class=teamType;
      info = await QUERYED(
        "post",
        "",
        " WDNewQuestion(limit: " +
          limit +
          ", offset: " +
          offset +
          ', where: { problemClassification: {_eq: "' +
          this.class +
          '"},_not: {competePriceSetting: {_eq: 0}}}) {id competePriceSetting questionTitle keyword  questionContent  answerNum   createTime }'
      );
      data = await QUERYED(
        "post",
        "",
        '   WDNewQuestion_aggregate(where: {problemClassification: {_eq: "' +
          this.class +
          '"},_not: {competePriceSetting: {_eq: 0}}}){  aggregate {   count} }'
      );
      console.log(info);
      this.problems.splice(0, this.problems.length);
      for (let i = 0; i < info.data.WDNewQuestion.length; i++) {
        this.problems.push({
          id: info.data.WDNewQuestion[i].id,
          competePriceSetting: info.data.WDNewQuestion[i].competePriceSetting,
          questionTitle: info.data.WDNewQuestion[i].questionTitle,
          questionContent: info.data.WDNewQuestion[i].questionContent,
          keyword: info.data.WDNewQuestion[i].keyword,
          answerNum: info.data.WDNewQuestion[i].answerNum,
          createTime: formatDate(
            Date.parse(info.data.WDNewQuestion[i].createTime)
          ),
        });
      }
      this.problemId = data.data.WDNewQuestion_aggregate.aggregate.count;
      this.totlePage = data.data.WDNewQuestion_aggregate.aggregate.count;
    },
  },
};
</script>
<style scoped>
@import "~@/styles/page/index.css";
.whole {
  width: 100%;
  display: inline-block;
  /* border:1px solid fuchsia */
  /* background-color: gainsboro; */
}
.head {
  width: 100%;
  display: inline-block;
}
.head_problem {
  width: 50%;
  /* float: right; */
  /* border:1px solid red; */
  /* margin-left: 200px; */
  /* margin: auto; */
  font-size: 12px;
  margin-bottom: 5px;
  text-align: right;
  display: inline-block;
}
.classification {
  width: 100%;
  text-align: center;
  background-color: white;
}
.center {
  width: 788px;
  margin: auto;
}
.Refresh {
  font-size: 15px;
}
.center_Navigation {
  width: 65%;
  margin: auto;
  text-align: left;
  /* border: 1px solid rebeccapurple; */
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  margin-top: 20px;
  /* display: inline-block; */
  /* vertical-align: bottom; */
}
.Navigationdddd {
  width: 43%;
  /* border: 1px solid red; */
  display: inline-block;
}
.down {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url("../../../../assets/icon/down.png");
  background-repeat: no-repeat;
  position: relative;
  top: 12px;
  left: 3px;
}
.refresh-icon {
  display: inline-block;
  height: 22px;
  width: 22px;
  background-image: url("../../../../assets/icon/Refresh.png");
  background-repeat: no-repeat;
  position: relative;
  top: 5px;
  border: 0;
}
.content {
  width: 66%;
  margin: auto;
  /* text-align: center; */
  /* border:1px solid orange; */
  /* background-color: white; */
  /* display: inline-block; */
  /* border: 1px solid blue; */
  float: right;
  /* margin-tight: 200px; */
}
.search {
  width: 100%;
  background-color: white;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  /* display: inline-block; */
  /* border: 1px solid yellow; */
  /* display: inline-block; */
  margin: auto;
}
.condition {
  width: 200px;
  height: 60px;
  line-height: 60px;
  margin: auto;
  /* border: 1px solid red; */
}
.condition .problem {
  color: #3998fb;
}
.condition > span:hover {
  cursor: pointer;
}
.condition > span {
  width: 100%;
  /* border: 1px solid red; */
  /* margin-left: 40px; */
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(92, 92, 92, 1);
  display: inline-block;
  border-bottom: 1px dashed #dadada;
  font-weight: bold;
}
.search-icon {
  width: 170px;
  height: 25px;
  padding-left: 5px;
}
.Screening-button {
  width: 76px;
  height: 32px;
  background: rgba(233, 243, 252, 1);
  border: 1px solid rgba(2, 130, 249, 1);
  border-radius: 3px;
  display: inline-block;
  line-height: 32px;
  text-align: center;
  margin-left: 10px;
  color: #037def;
  position: relative;
  top: 1px;
}

.Line {
  width: 100%;
  height: 1px;
  background-color: #b5b5b5;
  margin-top: 18px;
  display: inline-block;
}
.Specific_issues {
  width: 98%;
  padding-top: 10px;
  /* background-color: white; */
  /* display: inline-block; */
  margin: auto;
}
/* 分页 */

.page-nav {
  width: 99.3%;
  /* margin: auto; */
  /* position: relative;
    left: -5px; */
  /* border: 1px solid red; */
  display: inline-block;
}
/* el-pagination{
    width: 90%;
} */
.right {
  /* display: inline-block; */
  width: 230px;
  height: 200px;
  /* border: 1px solid red; */
  vertical-align: top;
  /* float: left; */
  /* margin: auto; */
  margin-left: 10px;
  background-color: white;
  text-align: center;
  float: right;
  border-top: 3px solid rgba(0, 132, 255, 1);
  /* position:fixed; */
  /* right: 0;
   left: 0; */
  /* top: -500px; */
  /* top: 385px;
   right: 0; */

  /* right: 0; */
}
/* 新 */
.new {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url("../../../../assets/icon/news0601.png");
  background-repeat: no-repeat;
  position: relative;
  /* top:3px; */
  left: 3px;
}
/* 火 */
.heat {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url("../../../../assets/icon/slices.png");
  background-repeat: no-repeat;
  position: relative;
  top: -5px;
  left: 5px;
}
/* 悬赏 */
.Appreciation {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url("../../../../assets/icon/Gold.png");
  background-repeat: no-repeat;
  position: relative;
  /* top: 5px; */
  left: 5px;
}
.tu1 {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: rgba(222, 236, 251, 1);
  border-radius: 50%;
  position: relative;
  left: -5px;
  top: 7px;
  margin-right: 5px;
}
.tu2 {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: rgba(254, 249, 225, 1);
  border-radius: 50%;
  position: relative;
  left: -5px;
  top: 7px;
  margin-right: 5px;
}
.tu3 {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: rgba(255, 241, 235, 1);
  border-radius: 50%;
  position: relative;
  left: -5px;
  top: 7px;
  margin-right: 5px;
}

/* 导航条 */

.content2 {
  /* text-align: center; */
  /* border:1px solid red; */
  width: 100%;
  height: 90px;
  display: inline-block;
  padding-top: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: black;
  /* line-height:35px; */
}
.content2 > div {
  width: 190px;
  /* height:70px; */
  /* float: left; */
  text-align: center;
  display: inline-block;
  overflow: hidden;
}
.content2 > div > span {
  display: inline-block;
  width: 100%;
  height: 35px;
  line-height: 35px;
  /* cursor:pointer */
}
.frame2,
.frame3,
.frame4,
.frame6,
.frame7,
.frame8 {
  /* border-right: 0; */
  border-right: 1px dashed #767676;

  /* border: 1px solid red; */
}
.frame .classification {
  color: #0084ff;
  /* font-weight: bold; */
  /* cursor:pointer */
}
.page-nav .el-pagination {
  text-align: center;
}
.frame > span {
  cursor: pointer;
}
</style>

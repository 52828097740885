<template>
    <!-- 分类内容 -->
    <div class="Classification_content">
        <div class="content2">
            <div class="frame" v-for="(teamType,index) in frame" :key="index" :class="'frame' + (index + 2)" >
                <span :class="{'classification':(classification==index)}"   @click="selectItem(index,teamType)">{{teamType}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data (){
    return{
      classification: -1,
      class:"",
    }
  },
    props:{   
   problems:{
      type:Object,
      required:true
    },
      frame:{
            type:Object,
            required:true
            }
          },
  
    methods:{
    async  selectItem(index,teamType){
        this.classification=index;
        this.class=this.teamType;

        //alert(problem)
        //alert(this.class)
            let info=  await  QUERY("post","",' WdQuestion(order_by: {createTime: desc}, where: {problemClassification: {_eq: "'+this.class+'"}}) {id competePriceSetting questionTitle keyword  questionContent  answerNum   createTime }');
            //  let data =await QUERY("post","",'   WdQuestion_aggregate {  aggregate {   count} }');   
        //alert(info)
            console.log(info)
             this.problems.splice(0,this.problems.length);
            for(let i=0;i<info.data.WdQuestion.length;i++){
                this.problems.push({
                   id:info.data.WdQuestion[i].id,
                    competePriceSetting:info.data.WdQuestion[i].competePriceSetting,
                    questionTitle:info.data.WdQuestion[i].questionTitle,
                    questionContent:info.data.WdQuestion[i].questionContent,
                    keyword:info.data.WdQuestion[i].keyword,
                    answerNum:info.data.WdQuestion[i].answerNum,
                    createTime:info.data.WdQuestion[i].createTime
                });
            } 
          // this.problemId=data.data.WdQuestion_aggregate.aggregate.count;  
        }
    }
    
}
</script>
<style scoped>
.Classification_content{
width: 100%;
text-align: center;

}
.content2{
/* text-align: center; */
/* border:1px solid red; */
width: 100%;
height:90px;
display: inline-block;
padding-top:10px;
font-size:16px;
font-family:Microsoft YaHei;
font-weight:300;
color:black;
/* line-height:35px; */
}
.content2>div{
     width: 190px;
     /* height:70px; */
    /* float: left; */ 
    text-align: center;
    display: inline-block;
    overflow: hidden;

}
.content2>div>span{
display: inline-block;
width: 100%;
height: 35px;
line-height: 35px;
/* cursor:pointer */
}
.frame2,.frame3,.frame4,.frame6,.frame7,.frame8{
/* border-right: 0; */
border-right:1px dashed #767676;


/* border: 1px solid red; */
}
.frame .classification{
  color: #0084FF;
  /* font-weight: bold; */
  /* cursor:pointer */
}

</style>